.sidebar {
    padding: 15px;
    width: 100%;
    height: 350px;
    background-image: url(../img/default-sidebar.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all 450ms;

    @include breakpoint(sm) {
        height: 450px; }

    @include breakpoint(md) {
        width: 400px;
        height: 100%;
        position: fixed;
        background-color: #f5f5f5; }

    @include breakpoint(lg) {
        width: 464px; }

    .site-info {
        padding: 90px 15px 0 15px;
        color: #fafafa;
        text-align: center;
        background-color: #86ad45;

        @include breakpoint(sm) {
            padding: 180px 100px 0 100px; }
        @include breakpoint(md) {
            padding: 0 20px;
            position: absolute;
            bottom: 40px; }
        @include breakpoint(lg) {
            padding: 0 30px; }

        h1 {
            text-shadow: 0 1px 3px rgba(0,0,0,0.3);
            letter-spacing: -2pt;
            margin-bottom: 0;
            @include breakpoint(sm) {
                letter-spacing: 0pt;
                margin-bottom: 10px;
                @include font-size(34); } }

        p {
            margin-bottom: 10px;
            text-shadow: 0 1px 3px rgba(0,0,0,0.3);
            @include line-height(24);
            @include font-size(16); }

        i {
            font-style: normal;
            margin-right: 10px; }

        .primary-info {
            border-bottom: solid 1px rgba(255,255,255,0.3);

            a {
                color: #E0E0E0; }
            a:hover,
            a:active {
                color: #fafafa; } }

        .secondary-info {

            p {
                margin: 20px 0 0;
                font-family: $sans;
                @include font-size(14); }
            a {
                padding: 5px;
                border-bottom: 0; } } } }
