.post-favorite {
    padding-right: 15px;

    h2 {
        @include breakpoint(md) {
            @include font-size(26); }

        a {
            border-bottom: none;
            text-decoration: none;
            color: #333337; } }

    p {
        @include breakpoint(md) {
            @include font-size(16);
            @include line-height(22); } } }
