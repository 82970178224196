.hero-image-404 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 500px;
    background-image: url(../img/default-404.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }

.title-404 {
    margin-top: 250px;
    color: #fff;
    text-align: center;
    @include font-size(22);
    text-transform: uppercase;
    letter-spacing: 0pt; }

.p-404 {
    color: #fff;
    text-align: center;
    @include font-size(18); }
