.btn-primary,
.label-primary,
.progress-bar-primary {
    background: #4761e2;
    border-color: darken(#4761e2, 5%);

    &:hover {
        background: darken(#4761e2, 10%);
        border-color: darken(#4761e2, 11%); } }

.btn-primary {
    background: #4761e2;
    color: #fff;
    font-family: $sans;

    &:hover {
        background-color: #2F49CA;
        color: #fff; }

    &:active {
        background-color: #2B43BD; } }

.btn-success,
.label-success,
.progress-bar-success {
    background: #1abc9c;
    border-color: darken(#1abc9c, 5%);

    &:hover {
        background: darken(#1abc9c, 10%);
        border-color: darken(#1abc9c, 11%); } }

.btn-warning,
.label-warning,
.progress-bar-warning {
    background: #f39c12;
    border-color: darken(#f39c12, 5%);

    &:hover {
        background: darken(#f39c12, 10%);
        border-color: darken(#f39c12, 11%); } }

.btn-danger,
.label-danger,
.progress-bar-danger {
    background: #e24747;
    border-color: darken(#e24747, 3%);

    &:hover {
        background: darken(#e24747, 7%);
        border-color: darken(#e24747, 8%); } }

.btn-info,
.label-info,
.progress-bar-info {
    background: #9b59b6;
    border-color: darken(#9b59b6, 5%);

    &:hover {
        background: darken(#9b59b6, 10%);
        border-color: darken(#9b59b6, 11%); } }

.alert-success {
    background: lighten(#1abc9c, 50%);
    border-color: lighten(#1abc9c, 40%);
    color: darken(#1abc9c, 15%); }

.alert-warning {
    background: lighten(#f39c12, 45%);
    border-color: lighten(#f39c12, 35%);
    color: darken(#f39c12, 15%); }

.alert-danger {
    background: lighten(#e24747, 40%);
    border-color: lighten(#e24747, 35%);
    color: darken(#e24747, 15%); }

.alert-info {
    background: lighten(#9b59b6, 40%);
    border-color: lighten(#9b59b6, 35%);
    color: darken(#9b59b6, 15%); }


.progress,
.alert,
.panel {
    border-radius: 0; }


.bs-example {
    margin-bottom: 20px; }
